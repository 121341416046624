import Img from 'gatsby-image'
import React, { Fragment, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import Lightbox from '../global/Lightbox'
import ArrowIcon from '../icons/ArrowIcon'
import styles from './PersonCard.module.scss'
import ShareLinkButton from './ShareLinkButton'

const PersonCard = ({ person }) => {
  // FIXME this isFlipped thing is weird. It's just always false? Why do we have it?
  // eslint-disable-next-line
  const [isFlipped, setIsFlipped] = useState(false)
  const [lightboxVisible, setLightboxVisible] = useState(false)
  const { name, illustration, body } = person

  return (
    <Fragment>
      <section className={`${styles.peopleCard}`}>
        <button
          onClick={() => setLightboxVisible(true)}
          className={`${styles.peopleCardImage} ${isFlipped && styles.active}`}
          aria-label={`Show ${name} Profile Card`}
        >
          <Img
            fluid={illustration.localFile.childImageSharp.fluid}
            imgStyle={{ objectFit: 'contain' }}
            alt={illustration.alternativeText}
          />
        </button>
      </section>

      <Lightbox isVisible={lightboxVisible} setIsVisible={setLightboxVisible}>
        <div className={`${styles.overlayContainer} sectionGrid2fr3fr`}>
          <div className={`${styles.imageContainer}`}>
            <Img
              fluid={illustration.localFile.childImageSharp.fluid}
              alt={illustration.alternativeText}
              imgStyle={{ objectFit: 'contain' }}
              className={`${styles.fullScreenImage}`}
            />
          </div>

          <div className={`${styles.downArrow}`}>
            <ArrowIcon />
          </div>

          <div className={`${styles.cardInfo}`}>
            <p className={`h3`}>{name}</p>
            <div className={`${styles.CardInfoBody}`}>
              <ReactMarkdown
                source={body}
                allowedTypes={[
                  'text',
                  'paragraph',
                  'break',
                  'emphasis',
                  'strong',
                  'list',
                  'listItem',
                  'blockquote',
                ]}
              />
            </div>
          </div>
          <ShareLinkButton
            className={`${styles.shareBtn}`}
            linkPage={`profile-cards/${person.person_id}/`}
          />
        </div>
      </Lightbox>
    </Fragment>
  )
}

export default PersonCard
