import { Link, graphql } from 'gatsby'
import React from 'react'

import Layout from '../components/global/Layout'
import PersonCard from '../components/global/PersonCard'
import SEO from '../components/global/Seo'
import TopNav from '../components/global/TopNav'
import styles from './profile-cards.module.scss'

const PeopleProfiles = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="Profile Cards"
        image={data.allStrapiPeople.nodes[0].illustration}
      />
      <TopNav />
      <section className={`backgroundBlack pt32 pb64`}>
        <div className={`pageMargin1Col pb64`}>
          <h1 className={`mt64 h2`}>Profile Cards</h1>
          <p className={`mb24`}>
            Learn the roles played by key people in this story by browsing their
            profile cards.
          </p>
          <p className={`h5 textColorFuzzy`}>
            Tap a card to read a short biography
          </p>
        </div>
        {data.allStrapiChapter.nodes.map(
          ({ chapter_slug, title, strapiId }) => {
            const chapterPeople = []
            data.allStrapiPeople.nodes.forEach(person => {
              if (person.section.chapter === strapiId)
                chapterPeople.push(person)
            })
            if (chapterPeople.length > 0) {
              return (
                <div key={`title-${chapter_slug}`} className={`mb32`}>
                  <div className={`pb16 pageMargin1Col`}>
                    <h2 className={`h4large`}>{title}</h2>
                  </div>
                  <div
                    className={`pageMargin1Col ${styles.cardsGrid} accentRed`}
                  >
                    {chapterPeople.map(person => (
                      <div key={`${person.person_id}`} className={`pb32`}>
                        <div className={`${styles.cardWrapper}`}>
                          <PersonCard person={person} />
                        </div>
                        <h3 className={`h5 mt16`}>{person.name}</h3>
                        <p className={`figcaption`}>{person.dates}</p>
                        <p className={`figcaption ${styles.shortBio}`}>
                          {person.short_bio}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              )
            } else {
              return null
            }
          }
        )}

        <div className={`pageMargin1Col mt64`}>
          <hr className={`mt32 mb32 backgroundSilver`} />
          <div
            className={`flexTabletUp justifyContentBetween textCenter ml32 mr32`}
          >
            <Link to={`/`}>
              <p className={`h5 mt16 mb16`}>Return to Home</p>
            </Link>

            <Link to={`/animation-gallery/`}>
              <p className={`h5 mt16 mb16`}>Animations</p>
            </Link>

            <Link to={`/illustration-gallery/`}>
              <p className={`h5 mt16 mb16`}>Illustrations</p>
            </Link>
          </div>
          <hr className={`mt32 backgroundSilver`} />
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    allStrapiChapter(sort: { fields: chapter_number }) {
      nodes {
        strapiId
        chapter_slug
        title
      }
    }

    allStrapiPeople(sort: { fields: section___section_code }) {
      nodes {
        person_id
        name
        dates
        short_bio
        body
        illustration {
          alternativeText
          caption
          localFile {
            publicURL
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        section {
          chapter
          section_slug
          section_title
        }
      }
    }
  }
`

export default PeopleProfiles
